import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.scss';

export const Footer: React.FC = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="footer">
      <div className="footer__container ">
        <Link to="/" className="icon icon--footer-logo container">
          <img src="img/nav/logo.svg" alt="logo" />
        </Link>

        <ul className="footer__ul container">
          <li className="footer__list">
            <Link to="https://www.instagram.com/mriya_donation?igsh=ZnVnam1razZleDlj" className="footer__link" target='_blank'>
              Instagram
            </Link>
          </li>
          <li className="footer__list">
            <Link to="https://x.com/mriya_donation?t=oJNGFO9OyTpXwz_nW3q-sw&s=09" className="footer__link" target='_blank'>
              X.com
            </Link>
          </li>
          <li className="footer__list">
            <Link to="https://testnets.opensea.io/collection/uadonation4" className="footer__link" target='_blank'>
              OpenSea
            </Link>
          </li>
          <li className="footer__list">
            <a href={`mailto:support@mriya-donation.com`} className="footer__link">
              support@mriya-donation.com
            </a>
          </li>
        </ul>

        <div className="footer__top container">
          <span className="footer__text">Back to top</span>
          <div className="icon icon--chevron" onClick={scrollToTop}>
            <img src="img/nav/chevron (arrow top).svg" alt="chevron (arrow top)" />
          </div>
        </div>
      </div>
    </footer>
  );
};
