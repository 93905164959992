import React, { useEffect, useMemo, useState } from 'react';
import './Drone.scss';
import Modal from '../Modal/Modal';
import axios from 'axios';
import { ethers } from 'ethers';
import { useAppDispatch } from '../../../../app/hooks';
import { setSuccessCheckout, setTotalAmount } from '../../../../features/tokenSlice';

interface Props {
  onClick?: () => void;
  details: any;
  networkError: boolean;
  checkNetwork: () => void;
}

export const Drone: React.FC<Props> = ({
  onClick,
  details,
  networkError,
  checkNetwork,
}) => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [token, setToken] = useState<any>(null);
  const droneLink = 'https://ipfs.io/ipfs/' + details[1].split('//')[1];
  const tokensAmount = BigInt(details[3]) - BigInt(details[2]);
  const status = useMemo(() => ({ title: 'Checkout', className: 'modal__buttons--buy-button' }), []);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.get(droneLink);
        const data = response.data;
        setToken(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetch();
  }, [droneLink]);

  const openModal = async () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    dispatch(setSuccessCheckout(false));
    setIsModalOpen(false);
    dispatch(setTotalAmount(1));
  };

  return (
    <>
      <article className="drone" onClick={openModal}>
        <div className="drone__img">
          <div className="drone__amount">{tokensAmount > 0 ? tokensAmount.toString() : '∞'}</div>
          <img
            className="drone__image"
            style={{ width: '300px' }}
            src={`https://ipfs.io/ipfs/${token?.image.split('//')[1]}`}
            alt="token"
          />
        </div>
        <p className="drone__name">{token?.name}</p>
        <p className="drone__price">{ethers.formatEther(details[4])} ETH</p>
      </article>
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        id={details[0]}
        details={token}
        tokensAmount={tokensAmount}
        price={ethers.formatEther(details[4])}
        onClick={onClick}
        networkError={networkError}
        checkNetwork={checkNetwork}
        status={status}
      />
    </>
  );
};
