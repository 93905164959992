import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { App } from '../App'
import { AboutPage } from '../modules/About';
import { TokensPage } from '../modules/Tokens';
import { ContactPage } from '../modules/Contact';
import { NotFoundPage } from '../modules/NotFoundPage';
import { Menu } from '../components/Navigation/components';

export const Root = () => {
  const location = useLocation()
  const hideNavigation = location.pathname === '/menu'

  return (
    <Routes>
      <Route path='/' element={<App hideNavigation={hideNavigation} />}>
        <Route index element={<AboutPage />} />
        <Route path='home' element={<Navigate to='/' />} />
        <Route path='menu' element={<Menu />} />
        <Route path='tokens' element={<TokensPage />} />
        <Route path='contact' element={<ContactPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
