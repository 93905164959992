import { PayloadAction, createSlice, Slice } from '@reduxjs/toolkit';

export interface ActionState {
  walletAddress: string;
  isWalletConnected: boolean;
  tokens: any[];
  totalAmount: number;
  checkoutLoading: boolean;
  successCheckout: boolean;
  errorCheckout: boolean;
}

const loadStateFromLocalStorage = (): ActionState => {
  const storedAddress = localStorage.getItem('walletAddress');
  const storedConnection = localStorage.getItem('isWalletConnected');
  const storedTokens = localStorage.getItem('tokens');

  return {
    walletAddress: storedAddress || '',
    isWalletConnected: storedConnection === 'true',
    tokens: storedTokens ? JSON.parse(storedTokens, reviver) : [],
    totalAmount: 1,
    checkoutLoading: false,
    successCheckout: false,
    errorCheckout: false,
  };
};

const saveStateToLocalStorage = (state: ActionState) => {
  localStorage.setItem('walletAddress', state.walletAddress);
  localStorage.setItem('isWalletConnected', state.isWalletConnected.toString());
  localStorage.setItem('tokens', JSON.stringify(state.tokens, replacer));
  localStorage.setItem('checkoutLoading', JSON.stringify(state.checkoutLoading));
  localStorage.setItem('successCheckout', JSON.stringify(state.successCheckout));
  localStorage.setItem('errorCheckout', JSON.stringify(state.errorCheckout));
};

const replacer = (key: string, value: any) => {
  if (typeof value === 'bigint') {
    return value.toString();
  }
  return value;
};

const reviver = (key: string, value: any) => {
  if (typeof value === 'string' && /^\d+n$/.test(value)) {
    return BigInt(value.slice(0, -1));
  }
  return value;
};

const initialState: ActionState = loadStateFromLocalStorage();

const tokenSlice: Slice<ActionState> = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setAddress: (state, action: PayloadAction<string>) => {
      state.walletAddress = action.payload;
      saveStateToLocalStorage(state);
    },
    setSuccessCheckout: (state, action: PayloadAction<boolean>) => {
      state.successCheckout = action.payload;
      saveStateToLocalStorage(state);
    },
    setErrorCheckout: (state, action: PayloadAction<boolean>) => {
      state.errorCheckout = action.payload;
      saveStateToLocalStorage(state);
    },
    setWalletConnection: (state, action: PayloadAction<boolean>) => {
      state.isWalletConnected = action.payload;
      saveStateToLocalStorage(state);
    },
    setTokens: (state, action: PayloadAction<any[]>) => {
      state.tokens = action.payload;
      saveStateToLocalStorage(state);
    },
    deleteTokens: (state) => {
      state.tokens = [];
      saveStateToLocalStorage(state);
    },
    setTotalAmount: (state, action: PayloadAction<number>) => {
      state.totalAmount = action.payload;
      saveStateToLocalStorage(state);
    },
    setCheckoutLoading: (state, action: PayloadAction<boolean>) => {
      state.checkoutLoading = action.payload;
      saveStateToLocalStorage(state);
    },
    plusQuantity: (state, action: PayloadAction<number>) => {
      // Additional reducer logic here
      saveStateToLocalStorage(state);
    },
    minusQuantity: (state, action: PayloadAction<number>) => {
      // Additional reducer logic here
      saveStateToLocalStorage(state);
    },
    init: state => {
      const loadedState = loadStateFromLocalStorage();
      state.walletAddress = loadedState.walletAddress;
      state.isWalletConnected = loadedState.isWalletConnected;
    },
  },
});

export const {
  setAddress,
  setWalletConnection,
  setTokens,
  deleteTokens,
  setTotalAmount,
  setCheckoutLoading,
  setSuccessCheckout,
  setErrorCheckout,
} = tokenSlice.actions;

export default tokenSlice.reducer;
