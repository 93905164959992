import React, { useEffect, useRef, useState } from 'react';
import { Drone, Filter, Pagination } from './components';
import './Tokens.scss';
import classNames from 'classnames';
import { ethers } from 'ethers';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  setAddress,
  setCheckoutLoading,
  setTokens,
  setWalletConnection,
  setSuccessCheckout,
  setErrorCheckout,
} from '../../features/tokenSlice';
import { useParams, useSearchParams } from 'react-router-dom';
import { REACT_APP_CONTRACT_ADDRESS, REACT_APP_PROVIDER, REACT_APP_TOKENS_ABI } from '../../vars';

export const TokensPage: React.FC = () => {
  const { walletAddress, isWalletConnected, tokens, totalAmount, errorCheckout } =
    useAppSelector((state) => state.token);
  const dispatch = useAppDispatch();

  const buttonAddress =
    walletAddress?.slice(0, 6) + '...' + walletAddress?.slice(-4);
  const [provider, setProvider] = useState<any>(null);
  const [signer, setSigner] = useState<any>(null);
  const [contract, setContract] = useState<any>(null);
  const contractAddress = REACT_APP_CONTRACT_ADDRESS as string;
  const abi = REACT_APP_TOKENS_ABI;
  const providerUrl = REACT_APP_PROVIDER as string;

  const { tokenFilter } = useParams<{ tokenFilter: string }>();
  const [searchParams, setSearchParams] = useSearchParams();
  const itemsPerPageOptions = ['4', '8', '16', 'all'];

  const [itemsPerPage, setItemsPerPage] = useState<string>(
    searchParams.get('perPage') || '16'
  );
  const [selectedSort, setSelectedSort] = useState<string>(
    searchParams.get('sort') || 'Id'
  );
  const [isDropdownSortOpen, setIsDropdownSortOpen] = useState<boolean>(false);
  const [isDropdownPerOpen, setIsDropdownPerOpen] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(
    +(searchParams.get('page') || '1')
  );
  const dropdownSortRef = useRef<HTMLDivElement>(null);
  const dropdownPerRef = useRef<HTMLDivElement>(null);
  const [networkError, setNetworkError] = useState(false);

  const firstIndex: number = (currentPage - 1) * +itemsPerPage;
  const lastIndex: number = Math.min(currentPage * +itemsPerPage, tokens.length);
  const numbers: any[] =
    itemsPerPage === 'all' ? tokens : tokens.slice(firstIndex, lastIndex);

  const fetchTokenDetails = async () => {
    try {
      const provider = new ethers.JsonRpcProvider(providerUrl);

      if (!contractAddress || !abi) {
        throw new Error('Contract address or ABI is not defined.');
      }

      const contract = new ethers.Contract(contractAddress, abi, provider);

      const tokenDetails = await contract.getPurchaseTokensDetails();
      dispatch(setTokens(tokenDetails));
    } catch (error) {
      console.error('Error fetching token details:', error);
    }
  };

  const requestAccount = async () => {
    console.log('Connect to Web3');
    if (window.ethereum) {
      try {
        const addresses = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        if (addresses.length > 0) {
          dispatch(setAddress(addresses[0]));
          dispatch(setWalletConnection(true));

          const newProvider = new ethers.BrowserProvider(window.ethereum);
          const newSigner = await newProvider.getSigner();
          const newContract = new ethers.Contract(
            contractAddress,
            abi,
            newSigner
          );

          setProvider(newProvider);
          setSigner(newSigner);
          setContract(newContract);

          await checkNetwork();
          fetchTokens(newContract);
        } else {
          handleDisconnect();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const checkNetwork = async () => {
    if (window.ethereum) {
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      if (chainId !== '0xa0c71fd') {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0xa0c71fd' }],
          });
          setNetworkError(false);
        } catch (error: any) {
          if (error.code === 4902) {
            console.error('Please add the Ethereum Mainnet to your wallet.');
            setNetworkError(true);
          } else if (error.code === 4001) {
            setNetworkError(true);
          } else {
            console.error('Error switching network:', error);
          }
        }
      } else {
        setNetworkError(false);
      }
    }
  };

  const handleDisconnect = () => {
    dispatch(setAddress(''));
    localStorage.removeItem('walletAddress');
    dispatch(setWalletConnection(false));
    setProvider(null);
    setSigner(null);
    setContract(null);
  };

  const fetchTokens = async (contract: any) => {
    try {
      const tokensList = await contract.getPurchaseTokensDetails();
      dispatch(setTokens(tokensList));
    } catch (error) {
      console.error('Error fetching tokens:', error);
    }
  };

  const purchaseToken = async (id: number, priceWei: bigint) => {
    if (contract) {
      try {
        await checkNetwork();
        dispatch(setCheckoutLoading(true));
        const tx = await contract.purchaseToken(id, totalAmount, {
          value: priceWei * BigInt(totalAmount),
        });
        await tx.wait();
        dispatch(setSuccessCheckout(true));
        fetchTokens(contract);
      } catch (error) {
        console.error('Error purchasing token:', error);
        dispatch(setErrorCheckout(true));
        setTimeout(() => {
          dispatch(setErrorCheckout(false));
        }, 1000);
      } finally {
        dispatch(setCheckoutLoading(false));
        setTimeout(() => {
          dispatch(setSuccessCheckout(false));
        }, 1000);
      }
    }
  };

  useEffect(() => {
    fetchTokenDetails();

    const handleAccountsChanged = (accounts: string[]) => {
      if (accounts.length > 0) {
        dispatch(setAddress(accounts[0]));
        localStorage.setItem('walletAddress', accounts[0]);
        dispatch(setWalletConnection(true));

        if (contract) {
          fetchTokens(contract);
        }
      } else {
        handleDisconnect();
      }
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (window.ethereum.removeListener) {
          window.ethereum.removeListener(
            'accountsChanged',
            handleAccountsChanged
          );
        }
      };
    }
  }, [contract]);

  useEffect(() => {
    if (tokens.length > 0) {
      const response = [...tokens];

      if (selectedSort === 'Expensivest') {
        response.sort((a, b) => b[4].toString() - a[4].toString());
      } else if (selectedSort === 'Cheapest') {
        response.sort((a, b) => a[4].toString() - b[4].toString());
      } else if (selectedSort === 'Id') {
        response.sort((a, b) => a[0].toString() - b[0].toString());
      }

      dispatch(setTokens(response));
    }
  }, [tokenFilter, selectedSort]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownSortRef.current &&
        !dropdownSortRef.current.contains(event.target as Node)
      ) {
        setIsDropdownSortOpen(false);
      }

      if (
        dropdownPerRef.current &&
        !dropdownPerRef.current.contains(event.target as Node)
      ) {
        setIsDropdownPerOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setSearchParams({
      page: currentPage.toString(),
      perPage: itemsPerPage,
      sort: selectedSort,
    });
  }, [currentPage, itemsPerPage, selectedSort, setSearchParams]);

  return (
    <section className='tokens container'>
      {networkError && (
        <div className="error-message">
          Please switch to the Ethereum Mainnet.
        </div>
      )}
      <div className='tokens__box'>
        <h1 className='tokens__title'>Tokens</h1>
        <button
          className={classNames('modal__web', {
            'modal__web--connected': isWalletConnected,
          })}
          onClick={isWalletConnected ? handleDisconnect : requestAccount}>
          <span>{isWalletConnected ? buttonAddress : 'Connect to Web3'}</span>
          <img src='img/drones/metamask-logo.svg' alt='metamask' />
        </button>
      </div>

      <Filter
        itemsPerPage={itemsPerPage}
        setItemsPerPage={setItemsPerPage}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
        isDropdownSortOpen={isDropdownSortOpen}
        setIsDropdownSortOpen={setIsDropdownSortOpen}
        isDropdownPerOpen={isDropdownPerOpen}
        setIsDropdownPerOpen={setIsDropdownPerOpen}
        dropdownSortRef={dropdownSortRef}
        dropdownPerRef={dropdownPerRef}
        setCurrentPage={setCurrentPage}
        itemsPerPageOptions={itemsPerPageOptions}
      />

      <div className='tokens__all'>
        {numbers.map((token, index) => (
          <Drone
            key={index}
            onClick={() => purchaseToken(token.id, BigInt(token[4]))}
            details={token}
            networkError={networkError}
            checkNetwork={checkNetwork}
          />
        ))}
      </div>

      {itemsPerPage !== 'all' && (
        <Pagination
          total={tokens.length}
          perPage={+itemsPerPage}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      )}
    </section>
  );
};
