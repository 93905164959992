import React from 'react';
import './Contact.scss';

export const ContactPage: React.FC = () => {
  return (
    <section className="contacts container">
      <h1 className="about__title">Contact us</h1>
      <p className="about__text">
        If you have any questions or suggestions, contact us through our mail or social networks. We are always open to cooperation and new ideas.
      </p>

      <div className="contacts__data">
        <div className="contacts__networks">
          <h2>Social networks</h2>
          <div className="contacts__social">
            <a href="https://www.instagram.com/mriya_donation?igsh=ZnVnam1razZleDlj" target='_blank'>
              <img src="img/links/insta.svg" alt="insta" />
            </a>
            <a href="https://x.com/mriya_donation?t=oJNGFO9OyTpXwz_nW3q-sw&s=09" target='_blank'>
              <img src="img/links/x.svg" alt="x" />
            </a>
          </div>
          <span>support@mriya-donation.com</span>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d325516.56932931335!2d30.203048838357034!3d50.40200750314156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40d4cf4ee15a4505%3A0x764931d2170146fe!2sKyiv%2C%2002000!5e0!3m2!1sen!2sua!4v1719408587513!5m2!1sen!2sua"
          style={{border:0}}
          loading="lazy"
        />
      </div>
     </section>
  );
};
