import React from 'react';
import './NotFoundPage.scss';

export const NotFoundPage: React.FC = () => {
  return (
    <div className="notfound__empty">
      <img
        className="notfound__empty"
        src="img/page-not-found.png"
        alt="not-found"
      />
      <h1 className="notfound__title">Page was not founded</h1>
    </div>
  );
};
